.TelegramLogin{
   
    width: 100%;
    height: 100%;
    
    &-bg{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-bg2{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-snBox{
        font-size: 15px;
        width: 80%;
        max-width: 800px;
        height: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background-color: rgba(216,202,186,0.9);
        border: 2px solid transparent;
        border-radius: 12px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        &_close{
            position: absolute;
            font-size: 15px;
            right: 10px;
            top: 5px;
            font-weight: bold;
            padding-left: 20px;
            padding-right: 20px;
            right: 10px;
            border: 2px solid transparent;
            border-radius: 12px;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            background-image: linear-gradient(to right, #c6ae97, #c6ae97), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
        }

        &_text{
            float: left;
            width: 100%;
            text-align: center;
            color: black;
            white-space: pre-line;
        }

        &_code{
            float: left;
            width: 100%;
            font-size: 18px;
            text-align: center;
            color: black;
            padding-bottom: 12px;
        }

        &_copyBtn{
            margin-top: 10px;
            text-align: center;
            width: 50%;
            max-width: 200px;
            background-color: #c6ae97;
            border: 2px solid transparent;
            border-radius: 12px;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            background-image: linear-gradient(to right, #c6ae97, #c6ae97), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
        }
    }

    &-snBox2{
        font-size: 15px;
        width: 100%;
        height: 100%;
        //width: 80%;
        //max-width: 800px;
        //height: 50%;
        position: absolute;
        //left: 50%;
        //top: 50%;
        //transform: translate(-50%,-50%);
        background-color: #F3F4EA;
        border: 2px solid transparent;
        border-radius: 12px;
        //background-clip: padding-box, border-box;
        //background-origin: padding-box, border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &_close2{
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 13px;
            line-height: 17px;
            background-color: #54a9eb;
            color: #fff;
            cursor: pointer;
            text-overflow: ellipsis;
            padding: 5px 14px 6px;
            border: none;
            border-radius: 10px !important
        }

        &_copyBtn2{
            margin-top: 10px;
            font-size: 13px;
            line-height: 17px;
            background-color: #54a9eb;
            color: #fff;
            cursor: pointer;
            text-overflow: ellipsis;
            padding: 5px 14px 6px;
            border: none;
            border-radius: 10px !important
        }
    }
}