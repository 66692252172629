.XContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    #tweets-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

:global(.tweet-body_root__HQXHG) {
    font-size: 10px;
  }