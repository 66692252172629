.HxgPage {
    position: relative;
    &__coin0aImg {
        z-index: 2;
        height: 6vh;
        margin-bottom: 20px;
        object-fit: contain;
        margin-bottom: 10%;
      }
    &-hxg{
        z-index: 2;
        width: 100%;
        height: 5vh;
        margin-bottom: 3%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        &__gray, &__yang, &__yin {
            width: 70%;
            height: 100%;
        }
        &__gray {
            visibility: visible;
            background-color: #555555;
        }
        &__yang {
            visibility: visible;
            background-color: #389594;
        }
        &__yin {
            visibility: visible;
            background: linear-gradient(to right, #389594 42%, transparent 42%, transparent 58%, #389594 58%); /* 中間透明 */
        }

        &__grayText{
            color: #00000000;
        }

        &__yinText, &__grayText, &__yangText, &__lessYangText, &__lessYinText {
            width: 10%;
        }

        &__gray.hidden, &__yang.hidden, &__yin.hidden, &__yinText.hidden,&__yangText.hidden, &__grayText.hidden, &__lessYangText.hidden, &__lessYinText.hidden { 
            display: none;
        }
    }
    &__hxgButtonsSet{
        z-index: 999;
        height: 10vh;
        // margin-top: -30%;
        position: relative;
    }
    &__hxgButtons {
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
    }
    &-hxgTitle{
        z-index: 2;
        height: 30%;
        &__img {
            height: 100%;
            margin-bottom: -30%;
        }
    }
    &__hxg3heads,&__hxg3words, &__hxg2heads1word, &__hxg2words1head {
        z-index: 999;
        height: 100%;
    }
}

.HxgPage-hxg.animateFadeOut {
    animation: fadeOut 0.3s forwards;
  }
  
.HxgPage-hxg.animateFadeIn {
    animation: fadeIn 0.3s forwards;
}

@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }