.Landing2_BG {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0f0f0f;
}

.Landing2 {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f2e2d7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../public/imgs/blue_bg.png");
  background-repeat: no-repeat;
  position: relative;
  //background-attachment: fixed;
  //background-position: center;
  background-size: cover;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    max-width: 800px;
  }

  &-clickable {
    cursor: pointer;
  }

  &-header {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: 0;
    width: 100%;

    &__bottonBlock {
      float: left;
      top:0;
      width: 5%;
      margin-left: -5%;

    }
    &__botton {
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 100%;
      padding-left: 20px;
      width: 100%;

      color: #ffffff;
    }

    &__logoBlock {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__logo {
      width: 50%;
      margin-top: 5px;
    }

  }

  &-bg {
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: fixed;
    overflow-x: hidden;
    position: relative;
    width: 100%;
  }

  &-moonBox {
    //position: absolute;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    pointer-events: none;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    @media screen and (min-width: 768px) {
      max-width: 800px;
    }

    &__moon {
      z-index: 10;
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: center;
      transform: translate(-50%, -50%);
      opacity: 0;

      @media screen and (max-width: 768px) {
        width: 80%;
      }

      @media screen and (min-width: 768px) {
        width: 50%;
      }
    }

    &__headText {
      z-index: 30;
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;

      @media screen and (max-width: 768px) {
        width: 80%;
      }

      @media screen and (min-width: 768px) {
        width: 50%;
      }
    }

    &__text {
      z-index: 20;
      width: 40%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    &__btns {
      z-index: 30;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;

      @media screen and (max-width: 768px) {
        width: 90%;
        height: 30%;
      }

      @media screen and (min-width: 768px) {
        width: 70%;
        height: 40%;
      }
    }

    &__btn {
      float: left;
      width: 100%;
      height: 18%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__imgBtn {
      float: left;
      width: 100%;
      cursor: pointer;
    }
  }

  &-longimg {
    z-index: 99;
    width: 100%;
    height: auto;
    padding-bottom: 0px;
    top: 0;
  }

  &-imgPadding {
    width: 100%;
    top: 0;
    padding-bottom: 0px;
  }

  &-arrow{
    position: fixed;
    z-index: 101;
    left: 50%;
    color: white;
    bottom: 5%;
    font-size: 15px;
  }

  &-footerDiv{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
