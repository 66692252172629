.FullScreenContent{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 99;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.8);
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    &-closeBtn{
        cursor: pointer;
        text-align: end;
        width: 95%;
        font-size: 25px;
    }

    &-content{
        left: 50%;
        width: 95%;
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
    }
}
