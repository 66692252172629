.Landing{
    width: 100%;
    height: 100%;
    background-color: #F2E2D7;
    display: flex;
    justify-content: center;
    
    &-clickable{
        cursor: pointer;
    }

    &-logo{
        width: 100%;
    }

    &-content{
        background-image: url('../../public/imgs/mc_bg.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        max-width: 800px;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        &__logo{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__img_frame{
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            align-items: center;
        
            @media screen and (min-width: 768px) {
                width: 100%;
            }
        
            @media screen and (max-width: 768px) {
                max-width: 100%;
            }
            
        }

        &__img{
            width: 100%;
        }

        &__img2{
            width: 15%;
        }

        &__img3{
            width: 50%;
            margin-top: 20px;
            margin-bottom: 20px;

        }

        &__img4{
            width: 90%;
        }
    }

    &-dialog{
        position: absolute;
        //z-index: 99;
        width: 95%;
        height: 95%;
        margin-top: 5%;
        margin-bottom: 5%;
        border-radius: 15px;
        background-color: #F3F4EA;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        &__close{
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 20px;
        }

        &__content{
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #F3F4EA;
            width: 90%;
            border-radius: 10px;

        }

        &__button{
            margin-top: 16px;
            font-size: 13px;
            line-height: 17px;
            background-color: #54a9eb;
            color: #fff;
            cursor: pointer;
            text-overflow: ellipsis;
            padding: 5px 14px 6px;
            border: none;
            border-radius: 10px !important
        }

        &__img2{
            max-width: 30%;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        &__img3{
            max-width: 85%;
        }
    }

    &-giftCodebg{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}