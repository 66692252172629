.AppInfo{
    float: left;
    @media screen and (min-width: 768px) {
        width: 45%;
    }

    @media screen and (max-width: 768px) {
        max-width: 100%;
    }

    &-logo{
        position: relative;
        
        @media screen and (min-width: 768px) {
            width: 8%;
        }
    
        @media screen and (max-width: 768px) {
            margin-top: 10px;
            left: 50%;
            transform: translate(-50%,0);
            height: 32px;
            padding-bottom: 5px;
        }
    }

    &-infoText{
        font-size: 30px;
        color: white;

        @media screen and (max-width: 768px) {
            text-align: center;
            margin: 10px;
            font-size: 2.5vh;
        }
    }
    
    &-introText1{
        @media screen and (min-width: 768px) {
            font-size: 20px;
            color: white;
            white-space: pre-line;
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        @media screen and (max-width: 768px) {
            font-size: 15px;
            font-weight: bold;
            color: white;
            white-space: pre-line;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }

    &-introText2{
        @media screen and (min-width: 768px) {
            font-size: 15px;
            color: white;
            white-space: pre-line;
            //margin: 20px;
        }
        @media screen and (max-width: 768px) {
            font-size: 13px;
            color: white;
            white-space: pre-line;
            margin: 10px;
        }
    }

    &-introText3{
        @media screen and (min-width: 768px) {
            font-size: 20px;
            color: white;
            white-space: pre-line;
            display: none;
            //margin: 20px;
        }
        @media screen and (max-width: 768px) {
            font-size: 12px;
            color: white;
            white-space: pre-line;
            margin: 10px;
        }
    }
}
