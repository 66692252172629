.TelegramCode{
    &-bg{
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        z-index: 99;
        background-color: transparent;
        background-color: rgba(0, 0, 0, 0.8);
    }

    &-bg2{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background-image: url('../../public/imgs/mc_bg.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    &-telegramBg{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 80%;
        height: 80%;
        background-color: #c6ae97;
        border: 2px solid transparent;
        border-radius: 12px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image:linear-gradient(to right, #c6ae97, #c6ae97), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
        display: flex;
        flex-direction: column;
        align-items: center;

        &_header{
            width: 100%;
            height: 8%;
        }

        &_closeBtn{
            cursor: pointer;
            float: right;
            margin-right: 10px;
            margin-top: 5px;
            font-weight: bold;
            padding-left: 20px;
            padding-right: 20px;
            background-color:  #c7b299;
            border: 2px solid transparent;
            border-radius: 12px;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            background-image: linear-gradient(to right, #c6ae97, #c6ae97), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
        }
    }

    &-telegramBg2{
        color: black;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 80%;
        height: 80%;
        background-color: #F3F4EA;
        border: 2px solid transparent;
        border-radius: 12px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_closeBtn{
            cursor: pointer;
            float: right;
            font-size: 13px;
            line-height: 17px;
            
            margin-right: 10px;
            margin-top: 5px;
            font-weight: bold;
            padding-left: 20px;
            padding-right: 20px;
            background-color: #54a9eb;
            color: #fff;
            text-overflow: ellipsis;
            padding: 5px 14px 6px;
            border: none;
            border-radius: 10px !important
        }
    }
}