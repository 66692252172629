.DivinationPage{
    &__titleImg {
        z-index: 2;
        height: 6%;
    }
    &-coin {
        z-index: 2;
        width: 100%;
        height: 3.5%;
        margin-top: 2%;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        &__coinImg {
            height:15px;
            margin-right: 5px;
        }
    }
    &-textBox {
        white-space: pre-line;
        color: #ffffff;
        margin-top: 5%;
        z-index: 2;
        height: 55%;
        width: 98%;
        background-color: #00000090;
        // font-size: 30px;
        overflow: auto;
        position: relative;
        padding-bottom: 3%;
        &__changQuestionButton{
            width: 35%;
            height: 10%;
            display:flex;
            justify-content: center;
            align-items: center;
            background-color: #1f181890;
            border: 1px solid #ffffff;
            padding: 0.5%;
            // font-size: 16px;
            margin: 0.5%;
        }
    }
    &-history {
        color: #ffffff;
        width: 100%;
        padding: 5px;
        &__leftButton {
            font-size: 20px;
            // position: absolute;
            bottom:0;
            float: left;
        }
        &__rightButton {
            font-size: 20px;
            float: right;

        }
    }
    &-infoBox {
        // font-size: 20px;
        margin-top: 5%;
        position: relative;
        border: 1px solid #ffffff; /* 內層邊框 */
        outline: 4px solid #ffffff; /* 外層邊框 */
        outline-offset: 5px; /* 邊框之間的距離 */
        padding: 10px; /* 確保內容不貼邊 */
        color: #ffffff;
        z-index: 2;
        height: 150px;
        width: 92%;
        &__fullLineButton{
            position: absolute;
            left: 2%;
            bottom: 0;
            width: 96%;
            height: 25%;
            display:flex;
            justify-content: center;
            align-items: center;
            background-color: #1f181890;
            border: 1px solid #ffffff;
            margin-bottom: 3%;
        }
        &-buttonSet {
            position: absolute;
            left: 1%;
            bottom: 0;
            width: 98%;
            height: 35%;
            display:flex;
            justify-content: center;
            margin-bottom: 3%;
            &__button {
                display:flex;
                justify-content: center;
                align-items: center;
                background-color: #1f181890;
                border: 1px solid #ffffff;
                padding: 0 0.5%;
                // font-size: 15px;
                margin: 0.5%;
            }
        }
        &-aiAskMeAnsInput {
            width:100%;
            height: 100%;
            &__textarea {
                width:calc(100% - 16px);
                height:calc(100% - 16px);
                // z-index: 998;
                padding: 8px;
                font-size: 16px;
                border-radius: 5px;
                border-width: 0px;
                background-color: #D4C0B3;
                resize: none;
            }
            &-enter {
                position: absolute;
                z-index: 999;
                right: 15px;
                bottom: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                &__text {
                  color: #000000;
                  font-size: 20px;
                }
              }
        }
    }
    &-askAiPopUp, &-linePopUp, &-feedBackPopUp {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 998;
        width: 100%;
        height: 100%;
        background-color: #000000d0;
        display: flex;
        justify-content: center;
        align-items: center;
        &__closeButton {
            position: absolute;
            top:0;
            right: 0;
            margin: 20px;
            color:#ffffff;
            font-size: 20px;
        }
        &-content {
            width: 60%;
            height: 40%;
            &__enter {
                margin-top: 5%;
                width: 30%;
            }
            &__Img {
                width: 80%;
            }
            &__Ans {
                margin-top: 10px;
                margin-bottom: 10px;
                width:60%;
                height:25px;
                padding: 8px;
                font-size: 16px;
                border-radius: 5px;
                border-width: 0px;
                background-color: #D4C0B3;
                resize: none;
            }
            &__textarea {
                width:calc(100% - 16px);
                height:calc(100% - 16px);
                padding: 8px;
                font-size: 16px;
                border-radius: 5px;
                border-width: 0px;
                background-color: #D4C0B3;
                resize: none;
            }
            &-enter {
                position: relative;
                margin-top: -35px;
                margin-right: 5px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                &__text {
                  color: #000000;
                //   font-size: 20px;
                }
              }
            &-text {
                text-align: center;
                color: #ffffff;
                &__lineButton{
                    margin-top: 10px;
                    height: 20px;
                }
                &__copyButton {
                    margin-top: 10px;
                    padding: 5px;
                    left: 2%;
                    bottom: 0;
                    width: 96%;
                    height: 25%;
                    background-color: #1f181890;
                    border: 1px solid #ffffff;
                    margin-bottom: 3%;
                }
                &__lineFriendButton {
                    text-decoration: none;
                    color: inherit;
                    display: inline-block;
                    margin-top: 10px;
                    padding: 5px;
                    left: 2%;
                    bottom: 0;
                    width: 96%;
                    height: 25%;
                    background-color: #06C755;
                    margin-bottom: 3%;
                    border-radius: 5px;
                }
            }
        }
    }
}

textarea::placeholder {
    color: #666666;
    opacity: 1;
  }