.MainContent{
    
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    @media screen and (min-width: 768px) {
        width: 45%;
        height: 95%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 75%;
        bottom: 1%;
        flex-direction: column;
    }

    @media screen and (max-width: 375px) {
        width: 100%;
        height: 70%;
        bottom: 1%;
        flex-direction: column;
    }

    &-imgpc{
        width: 100%;
        height: 100%;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    &-imgmobile{
        width: 100%;
        height: 100%;
        margin-bottom: 5px;
        @media screen and (min-width: 769px) {
            display: none;
        }
    }

    &-inputframe{
        width: 70%;
        height: 4.5%;
        float: left;
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        border: 2px solid transparent;
        border-radius: 12px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #000000 0.5, #000000 0.5), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: white;
        
        @media screen and (min-width: 768px) {
            bottom: 1.8%;
        }
        @media screen and (max-width: 768px) {
            bottom: 1.3%;
        }
        
        &_input{
            background: transparent;
            border-style: none;
            height: 70%;
            line-height: 100%;
            width: 90%;
            font-size: 15px;
            color: white;
            float: left;
            font-weight: bold;
        }

        &_sendBtn{
            @media screen and (min-width: 768px) {
                height: 80%;
                width: 6%;
                top: 5px;
                right: 5px;
            }
            @media screen and (max-width: 768px) {
                height: 100%;
                width: 7%;
                top: 0;
                right: 2px;
            }
            position: absolute;
            
            border-style: none;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 80%;
            background-image: url(../../public/imgs/telegram_btn.png) ;
        }
    }

    &-animation{
        background-color: transparent;
        position: absolute;
        overflow: hidden;
        left: 12%;
        overflow-y: auto;
        
        @media screen and (min-width: 768px) {
            width: 76%;
            height: 84%;
            top: 8%;
        }

        @media screen and (max-width: 768px) {
            width: 75%;
            height: 85%;
            top: 7%;
        }

        &_chat{
            position: relative;
            left: 0%;
            width: 100%;
            top: 0;
        }
    }
}

.MainContentEn{
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    @media screen and (min-width: 768px) {
        width: 45%;
        height: 95%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 64%;
        bottom: 1%;
        flex-direction: column;
    }

    @media screen and (max-width: 375px) {
        width: 100%;
        height: 60%;
        bottom: 1%;
        flex-direction: column;
    }
}