.Footer{
    width: 90%;
    color: white;
    display: flex;
    justify-content: center;

    &-content{
        font-size: 8px;
        float: left;
        cursor: pointer;
    }
}

.Footer2{
    width: 90%;
    color: black;
    display: flex;
    justify-content: center;

    &-content{
        font-size: 8px;
        float: left;
        cursor: pointer;
    }
}

.Footer3{
    width: 90%;
    color: #60a09d;
    display: flex;
    justify-content: center;
    align-items: center;

    &-content{
        font-size: 8px;
        float: left;
        cursor: pointer;
    }
}
