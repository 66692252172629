.ChatProcess {

    &-bg{
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        z-index: 99;
        background-color: transparent;
        background-color: rgba(0, 0, 0, 0.8);
    }

    &-imgFullBg{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background-color: transparent;
        background-color: rgba(0, 0, 0, 0.8);
    }

    &-bgframe{
        position: absolute;
        height: 98%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        @media screen and (max-width: 768px) {
            width: 100%;
        }

        @media screen and (min-width: 769px) {
            //width: 100%;
        }

        &_img{
            height: 100%;
            top: 0;
        }
    
        &_imgpc{
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    
        &_imgmobile{
            width: 100%;
            @media screen and (min-width: 769px) {
                display: none;
            }
        }

        &_imgpc2{
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    
        &_imgmobile2{
            width: 100%;
            @media screen and (min-width: 769px) {
                display: none;
            }
        }
    }

    &-header{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 86%;
        height: 5%;
        @media screen and (min-width: 768px) {
            top: 1.8%;
        }
        @media screen and (max-width: 768px) {
            top: 1%;
        }

        &_closeBtn{
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 5px;
            font-weight: bold;
            padding-left: 20px;
            padding-right: 20px;
            right: 10px;
        }

        &_creditBox{
            height: 100%;
            font-size: 15px;
            display: flex;
            align-items: center;
        }

        &_coin{
            height: 50%;
            padding-right: 5px;     
        }
    }

    &-interactiveFrame{
        background-color: transparent;
        position: absolute;
        overflow: hidden;
        display: flex;
        flex-direction: column-reverse;
        overflow: auto;
        overflow-y: auto;
        -webkit-overflow-scrolling:touch;
        align-items: center;

        @media screen and (min-width: 768px) {
            left: 12%;
            width: 76%;
            height: 84%;
            top: 8%;
        }

        @media screen and (max-width: 768px) {
            left: 7%;
            width: 86%;
            height: 86%;
            top: 7%;
        }
        overflow-y: auto;

        &_lineFrame{
            width: 100%;
        }

        &_botTextObj{
            background-color: #c6ae97;
            border: 2px solid transparent;
            border-radius: 12px;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            background-image:linear-gradient(to right, #c6ae97, #c6ae97), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
            //background-image: linear-gradient(to right, #c6ae97, #c6ae97), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
            max-width: 80%;
            height: auto;
            text-align: left;
            //font-size: 20px;
            margin: 15px;
            margin-top: 5px;
            margin-bottom: 5px;
            margin-left: 10px;
            padding: 5px;
            white-space: pre-wrap;
            word-wrap: break-word;
            position: relative;
            float: left;
        }

        
        &_botTextObj::after{
            color: #c6ae97;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            left: -4px;
            bottom: 3px;
            border-bottom: -5px solid transparent;
            border-right: 7px solid #c6ae97;
            border-top: 5px solid transparent;
        } 

        &_botTextObj::before{
            color: #c6ae97;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            left: -9px;
            bottom: 1px;
            border-top: 10px solid transparent ;
            border-bottom: -5px solid transparent;
            border-right: 10px solid #b6822e;
        }
     
        &_yourTextObj{
            background-color: white;
            border: 2px solid transparent;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            background-image:linear-gradient(to right, #c6ae97, #c6ae97), linear-gradient(25deg, #b6822e, #f7e190, #905924, #d89c49);
            max-width: 80%;
            height: auto;
            text-align: right;
            //font-size: 20px;
            border-radius: 10px;
            margin: 15px;
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 10px;
            padding: 5px;
            white-space: pre-wrap;
            word-wrap: break-word;
            float: right;
            position: relative;
        }

        &_yourTextObj::after{
            color: #c6ae97;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            right: -4px;
            bottom: 3px;
            border-bottom: -5px solid transparent;
            border-left: 7px solid #c6ae97;
            border-top: 5px solid transparent;
        } 

        &_yourTextObj::before{
            color: #c6ae97;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            right: -9px;
            bottom: 1px;
            border-top: 10px solid transparent ;
            border-bottom: -5px solid transparent;
            border-left: 10px solid #b6822e;
        }

        &_btnFrame{
            max-width: 80%;
        }

        &_button{
            float: left;
        }

        &_botImgObj{
            background-color: #c6ae97;
            border: 2px solid transparent;
            border-radius: 12px;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            background-image:linear-gradient(to right, #c6ae97, #c6ae97), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
            max-width: 45%;
            height: auto;
            text-align: left;
            margin: 15px;
            margin-left: 10px;
            padding: 5px;
            white-space: pre-wrap;
            word-wrap: break-word;
            position: relative;
            float: left;
        }

        &_botImgObj2{
            background-color: transparent;
            border: 2px solid transparent;
            border-radius: 12px;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            //background-image:linear-gradient(to right, #c6ae97, #c6ae97), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
            max-width: 65%;
            height: auto;
            text-align: left;
            //font-size: 20px;
            margin: 15px;
            margin-left: 10px;
            padding: 5px;
            white-space: pre-wrap;
            word-wrap: break-word;
            position: relative;
            float: left;
        }

        &_closeZoomIn{
            cursor: pointer;
            z-index: 1;
            position: fixed;
            top: 2px;
            right: 2px;
            max-height: 20%;
            padding: 15px;
            font-size: 30px;
            color: white;
        }

        &_zoomInPic{
            z-index: 1;
            position: fixed;
            max-width: 100%;
            max-height: 80%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &_hexImg{
            cursor: pointer;
            width: 100%;
            float: left;
        }

        &_tossImg{
            width: 100%;
            float: left;
        }

        &_innerButton{
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            color: white;
            border-radius: 5px;
            margin: 3px;
            padding: 5px;
            cursor: pointer;
        }
    }

    &-inputframe{
        width: 80%;
        height: 4%;
        float: left;
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        border: 2px solid transparent;
        border-radius: 5px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #000000 0.5, #000000 0.5), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
        left: 50%;
        transform: translate(-50%, 0);
        @media screen and (min-width: 768px) {
            bottom: 1.8%;
        }
        @media screen and (max-width: 768px) {
            bottom: 1%;
        }
        
        &_input{
            background: transparent;
            background-color: black;
            border-style: none;
            height: 100%;
            line-height: 100%;
            width: 90%;
            font-size: 16px;
            color: white;
            float: left;
            font-weight: bold;
        }

        &_sendBtn{
            @media screen and (min-width: 768px) {
                top: 5px;
                right: 5px;
            }
            @media screen and (max-width: 768px) {
                top: 0;
                right: 2px;
            }
            height: 100%;
            line-height: 100%;
            max-width: 10%;
            float: left;
            border-style: none;
            background-color: red;
            color: #fff;
            text-align: center;
        }
    }

    &-inputframe2{
        cursor: pointer;
        width: 80%;
        height: 6%;
        margin-left: 3px;
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        border-radius: 5px;
        left: 50%;
        transform: translate(-50%, 0);
        @media screen and (min-width: 768px) {
            bottom: 7%;
        }
        @media screen and (max-width: 768px) {
            bottom: 4%;
        }

        &_input{
            float: left;
            width: 90%;
            height: 100%;
            border-style: none;
            padding: 0;
            background: transparent;
            line-height: 100%;
            font-size: 16px;
            color: white;
            font-weight: bold;
        }

        &_sendBtn{
            height: 100%;
            line-height: 100%;
            width: 10%;
            float: left;
            border-style: none;
            color: #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-telegramBg{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 80%;
        height: 80%;
        background-color: #c6ae97;
        border: 2px solid transparent;
        border-radius: 12px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image:linear-gradient(to right, #c6ae97, #c6ae97), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
        display: flex;
        flex-direction: column;
        align-items: center;

        &_header{
            width: 100%;
            height: 8%;
        }

        &_closeBtn{
            cursor: pointer;
            float: right;
            margin-right: 10px;
            margin-top: 5px;
            font-weight: bold;
            padding-left: 20px;
            padding-right: 20px;
            background-color:  #c7b299;
            border: 2px solid transparent;
            border-radius: 12px;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            background-image: linear-gradient(to right, #c6ae97, #c6ae97), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
        }

        &_telegramFrame{
            height: 90%;
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            overflow-y: scroll;
            
        }

        &_img1{
            max-width: 90%;
            max-height: 45%;
            float: left;
        }
    }

    &-closeDialog{
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;

        &__content{
            width: 80%;
        }

        &__buttonFrame{
            width: 100%;
            padding-top: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        &__button{
            width: 40%;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            color: white;
            border-radius: 5px;
            margin: 3px;
            padding: 5px;
            cursor: pointer;
        }
    }

    &-noShow{
        display: none;
    }

    &-telegramBtn{
        margin-top: 16px;
        font-size: 13px;
        line-height: 17px;
        background-color: #54a9eb;
        color: #fff;
        cursor: pointer;
        text-overflow: ellipsis;
        padding: 5px 14px 6px;
        border: none;
        text-align: center;
        border-radius: 10px !important
    }
}

.ChatProcess2{
    &-bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background-color: transparent;
    }

    &-bgframe{
        position: absolute;
        background-color: #F3F4EA;
        height: 95%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        
        @media screen and (min-width: 769px) {
            width: 90%;
        }

        @media screen and (max-width: 768px) {
            width: 90%;
        }

        &__img{
            position:absolute;
            top: 0;
            width: 75%;
            left: 50%;
            transform: translate(-50%, 0%);
            
        }

        &__imgBottom{
            position:absolute;
            bottom: 0;
            width: 75%;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0%);
        }
    }

    &-header{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 95%;
        height: 5%;
        
        @media screen and (min-width: 768px) {
            top: 1.8%;
        }
        @media screen and (max-width: 768px) {
            top: 1%;
        }

        &_closeBtn{
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 5px;
            font-weight: bold;
            padding-right: 5px;
            right: 0px;
            
        }

        &_creditBox{
            height: 100%;
            font-size: 15px;
            display: flex;
            align-items: center;
        }

        &_coin{
            height: 50%;
            padding-right: 5px;     
        }
    }

    &-interactiveFrame{
        background-color: transparent;
        position: absolute;
        overflow: hidden;
        display: flex;
        flex-direction: column-reverse;
        overflow: auto;
        overflow-y: auto;
        align-items: center;
        width: 90%;
        left: 5%;
        
        @media screen and (min-width: 768px) {
           height: 79%;
            top: 7%;
        }

        @media screen and (max-width: 768px) {
            height: 83%;
            top: 5%;
        }
        overflow-y: auto;

        &_botTextObj{
            background-color: #e2d3ea;
            border: 2px solid transparent;
            border-radius: 12px 12px 12px 0px;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            max-width: 80%;
            height: auto;
            text-align: left;
            margin: 15px;
            margin-top: 5px;
            margin-bottom: 5px;
            margin-left: 10px;
            padding: 5px;
            white-space: pre-wrap;
            word-wrap: break-word;
            position: relative;
            float: left;
        }

        &_botTextObj::after{
            color: white;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            left: -4px;
            bottom: 3px;
            border-bottom: -5px solid transparent;
            border-right: 7px solid #e2d3ea;
            border-top: 5px solid transparent;
        } 

        &_botTextObj::before{
            color: white;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            left: -9px;
            bottom: 1px;
            border-top: 10px solid transparent ;
            border-bottom: -5px solid transparent;
            border-right: 10px solid #e2d3ea;
        }

        &_yourTextObj{
            background-color: #d0efef;
            border: 2px solid transparent;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            max-width: 80%;
            height: auto;
            text-align: left;
            border-radius: 12px 12px 0px 12px;
            margin: 15px;
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 10px;
            padding: 5px;
            white-space: pre-wrap;
            word-wrap: break-word;
            float: right;
            position: relative;
        }

        &_yourTextObj::after{
            color: #fff;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            right: -4px;
            bottom: 3px;
            border-bottom: -5px solid transparent;
            border-left: 7px solid #d0efef;
            border-top: 5px solid transparent;
        } 

        &_yourTextObj::before{
            color: #fff;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            right: -9px;
            bottom: 1px;
            border-top: 10px solid transparent ;
            border-bottom: -5px solid transparent;
            border-left: 10px solid #d0efef;
        }

        &_innerButton{
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            color: white;
            border-radius: 5px;
            margin: 3px;
            padding: 5px;
            cursor: pointer;
        }
    }

    &-inputframe{
        width: 80%;
        height: 4%;
        float: left;
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        border: 2px solid transparent;
        border-radius: 5px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #000000 0.5, #000000 0.5), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
        left: 50%;
        transform: translate(-50%, 0);
        @media screen and (min-width: 768px) {
            bottom: 1.8%;
        }
        @media screen and (max-width: 768px) {
            bottom: 1%;
        }
        
        &_input{
            background: transparent;
            background-color: black;
            border-style: none;
            height: 100%;
            line-height: 100%;
            width: 90%;
            font-size: 16px;
            color: white;
            float: left;
            font-weight: bold;
        }

        &_sendBtn{
            @media screen and (min-width: 768px) {
                top: 5px;
                right: 5px;
            }
            @media screen and (max-width: 768px) {
                top: 0;
                right: 2px;
            }
            height: 100%;
            line-height: 100%;
            max-width: 10%;
            float: left;
            border-style: none;
            background-color: red;
            color: #fff;
            text-align: center;
        }
    }

    &-inputframe2{
        cursor: pointer;
        width: 80%;
        height: 6%;
        margin-left: 3px;
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        border-radius: 5px;
        left: 50%;
        transform: translate(-50%, 0);
        @media screen and (min-width: 768px) {
            bottom: 7%;
        }
        @media screen and (max-width: 768px) {
            bottom: 4%;
        }

        &_input{
            float: left;
            width: 90%;
            height: 100%;
            border-style: none;
            padding: 0;
            background: transparent;
            line-height: 100%;
            font-size: 16px;
            color: white;
            font-weight: bold;
        }

        &_sendBtn{
            height: 100%;
            line-height: 100%;
            width: 10%;
            float: left;
            border-style: none;
            color: #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            
        }
    }

    &-closeDialog{
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left: 0;
        background-color: #F3F4EA;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;

        &__content{
            width: 80%;
            text-align: center;
            white-space: pre-wrap;
        }

        &__buttonFrame{
            width: 100%;
            padding-top: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        &__button{
            width: 35%;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            color: white;
            border-radius: 5px;
            margin: 3px;
            padding: 5px;
            cursor: pointer;
        }
    }
}

.ChatProcess3{
    &-bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background-color: transparent;
    }

    &-bgframe{
        position: absolute;
        //background-color: white;
        background-color: rgba($color: #012121, $alpha: 0.95);
        //opacity: 0.9;
        color: #e5decf;
        height: 95%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        
        @media screen and (min-width: 769px) {
            width: 90%;
        }

        @media screen and (max-width: 768px) {
            width: 90%;
        }

        &__img{
            position:absolute;
            top: 0;
            width: 75%;
            left: 50%;
            transform: translate(-50%, 0%);
            
        }

        &__imgBottom{
            position:absolute;
            bottom: 0;
            width: 75%;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0%);
        }
    }

    &-header{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 95%;
        height: 5%;
        
        @media screen and (min-width: 768px) {
            top: 1.8%;
        }
        @media screen and (max-width: 768px) {
            top: 1%;
        }

        &_closeBtn{
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 5px;
            font-weight: bold;
            padding-right: 5px;
            right: 0px;
            
        }

        &_creditBox{
            height: 100%;
            font-size: 15px;
            display: flex;
            align-items: center;
        }

        &_coin{
            height: 50%;
            padding-right: 5px;     
        }
    }

    &-interactiveFrame{
        background-color: transparent;
        position: absolute;
        overflow: hidden;
        display: flex;
        flex-direction: column-reverse;
        overflow: auto;
        overflow-y: auto;
        align-items: center;
        width: 90%;
        left: 5%;
        
        @media screen and (min-width: 768px) {
           height: 79%;
            top: 7%;
        }

        @media screen and (max-width: 768px) {
            height: 83%;
            top: 5%;
        }
        overflow-y: auto;

        &_botTextObj{
            color: black;
            background-color: #d3ccba;
            border: 2px solid transparent;
            border-radius: 12px 12px 12px 0px;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            max-width: 80%;
            height: auto;
            text-align: left;
            margin: 15px;
            margin-top: 5px;
            margin-bottom: 5px;
            margin-left: 10px;
            padding: 5px;
            white-space: pre-wrap;
            word-wrap: break-word;
            position: relative;
            float: left;
        }

        &_botTextObj::after{
            color: black;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            left: -4px;
            bottom: 3px;
            border-bottom: -5px solid transparent;
            border-right: 7px solid #d3ccba;
            border-top: 5px solid transparent;
        } 

        &_botTextObj::before{
            color: black;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            left: -9px;
            bottom: 1px;
            border-top: 10px solid transparent ;
            border-bottom: -5px solid transparent;
            border-right: 10px solid #d3ccba;
        }

        &_yourTextObj{
            color: black;
            background-color: #a7d3cb;
            border: 2px solid transparent;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            max-width: 80%;
            height: auto;
            text-align: left;
            border-radius: 12px 12px 0px 12px;
            margin: 15px;
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 10px;
            padding: 5px;
            white-space: pre-wrap;
            word-wrap: break-word;
            float: right;
            position: relative;
        }

        &_yourTextObj::after{
            color: black;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            right: -4px;
            bottom: 3px;
            border-bottom: -5px solid transparent;
            border-left: 7px solid #a7d3cb;
            border-top: 5px solid transparent;
        } 

        &_yourTextObj::before{
            color: black;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            right: -9px;
            bottom: 1px;
            border-top: 10px solid transparent ;
            border-bottom: -5px solid transparent;
            border-left: 10px solid #a7d3cb;
        }

        &_innerButton{
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            color: #e5decf;
            border-radius: 5px;
            margin: 3px;
            padding: 5px;
            cursor: pointer;
        }
    }

    &-inputframe{
        width: 80%;
        height: 4%;
        float: left;
        position: absolute;
        background-color: #565656;
        border: 2px solid transparent;
        border-radius: 5px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #000000 0.5, #000000 0.5), linear-gradient(25deg, #d89c49, #905924, #f7e190, #b6822e);
        left: 50%;
        transform: translate(-50%, 0);
        @media screen and (min-width: 768px) {
            bottom: 1.8%;
        }
        @media screen and (max-width: 768px) {
            bottom: 1%;
        }
        
        &_input{
            background: transparent;
            background-color: black;
            border-style: none;
            height: 100%;
            line-height: 100%;
            width: 90%;
            font-size: 16px;
            color: white;
            float: left;
            font-weight: bold;
        }

        &_sendBtn{
            @media screen and (min-width: 768px) {
                top: 5px;
                right: 5px;
            }
            @media screen and (max-width: 768px) {
                top: 0;
                right: 2px;
            }
            height: 100%;
            line-height: 100%;
            max-width: 10%;
            float: left;
            border-style: none;
            background-color: red;
            color: #fff;
            text-align: center;
        }
    }

    &-inputframe2{
        cursor: pointer;
        width: 80%;
        height: 6%;
        margin-left: 3px;
        position: absolute;
        background-color: #333333;
        border-radius: 5px;
        left: 50%;
        transform: translate(-50%, 0);
        @media screen and (min-width: 768px) {
            bottom: 7%;
        }
        @media screen and (max-width: 768px) {
            bottom: 4%;
        }

        &_input{
            float: left;
            width: 90%;
            height: 100%;
            border-style: none;
            padding: 0;
            background: transparent;
            line-height: 100%;
            font-size: 16px;
            color: white;
            font-weight: bold;
        }

        &_sendBtn{
            height: 100%;
            line-height: 100%;
            width: 10%;
            float: left;
            border-style: none;
            color: #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-closeDialog{
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left: 0;
        background-color: rgba($color: #012121, $alpha: 0.95);
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        color: e5decf;

        &__content{
            color: #e5decf;
            width: 80%;
            text-align: center;
            white-space: pre-wrap;
        }

        &__buttonFrame{
            width: 100%;
            padding-top: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        &__button{
            width: 35%;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            color: #e5decf;
            border-radius: 5px;
            margin: 3px;
            padding: 5px;
            cursor: pointer;
        }
    }

}