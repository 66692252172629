

.OnlineHxgPage {
    &__coin0aImg {
      z-index: 2;
      height: 6%;
      margin-bottom: 20px;
      object-fit: contain;
      margin-bottom: 10%;
    }
    &__coin0bImg, &__coin0cImg {
      z-index: 2;
      height: 6%;
      margin-bottom: 1%;
      object-fit: contain;
    }
    &-button{
      height: 25%;
      width: 100%;
      margin: 5% 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
        &__coin2Img {
          height: 20%;
          object-fit: contain;
          margin-bottom: -5%;
        }
    }
}

.preload-img {
  display: none;  /* 完全隱藏圖片 */
}

.OnlineHxgPage-button.animateFadeOut {
  animation: fadeOut 0.3s forwards;
}

.OnlineHxgPage-button.animateFadeIn {
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}