  .MenuPopUp {
      position: absolute;
      z-index:999;
      width:100%;
      height:100%;
      &__closeButton {
        width:100%;
        height:100%;
      }
      &-content{
        position: absolute;
        top:0;
        left: 0;
        z-index:999;
        background-color: rgba(13, 8, 46, 0.85);
        width:80%;
        height:100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-drawCardButton {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          &__titleTextImg {
            width: 35%;
            margin: 6%;
          }
          &__titleImg {
            width: 80%;
          }
        }
        &-historyLogoButton, &-indexButton, &-storyPageButton, &-takashimaPageButton{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          &__textImg {
            margin: 5%;
            width: 25%;
          }
          &__text2Img {
            margin: 5%;
            width: 37%; 
          }
        }
        &__line {
          margin: 5%;
          width: 96%;
        }

      }
}
