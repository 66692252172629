.RandomHxgPage {
    &__coin0aImg {
        z-index: 2;
        height: 6%;
        margin-bottom: 20px;
        object-fit: contain;
        margin-bottom: 10%;
      }
    &__textImg {
        z-index: 2;
        height: 25%;
        max-width: 100%;
        object-fit: contain;
    }
    &-button{
        z-index: 999;
        display: flex;
        position: relative;
        height: 40%;
        margin-top: 5%;
        &__img1, &__img2, &__img3, &__img4{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &__img1{
            object-fit: contain;
            height: 25%;

        }
        &__img2{
            object-fit: contain;
            height: 50%;

        }
        &__img3{
            object-fit: contain;
            height: 70%;

        }
        &__img4{
            object-fit: contain;
            height: 90%;
        }
    }

}

.RandomHxgPage-button__img2.animate {
    transform: translate(-50%, -50%) rotate(720deg);
    transition:transform 3s linear;
}

.RandomHxgPage-button__img3.animate {
    transform: translate(-50%, -50%) rotate(480deg);
    transition:transform 2s linear;
}

.RandomHxgPage-button__img4.animate {
    transform: translate(-50%, -50%) rotate(240deg);
    transition:transform 1s linear;
}