
.MoreInfoPage {
    &__infoImg {
      width: 100%;
      margin-bottom: 20px;
    }

    &__setInfo {
      z-index: 998;
      height: 40%;
      width: 90%;
      margin: 0;
      padding: 8px;
      font-size: 16px;
      border-radius: 5px;
      border-width: 0px;
      background-color: #D4C0B3;
      resize: none;
    }

    &-enter {
      z-index: 999;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &__text {
        margin-top: -30px;
        font-size: 20px;
      }
    }
}