  .QuestionPage {
      &__askQuestionImg {
        width: 100%;
        margin-bottom: 20px;
      }

      &__setQuestion {
        z-index:998;
        height: 40px;
        width: calc(90% - 25px);
        margin: 0;
        padding: 8px;
        padding-right: 25px;
        font-size: 16px;
        border-radius: 5px;
        border-width: 0px;
        background-color: #D4C0B3;
        resize: none;
      }

      &-enter {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__button {
          z-index: 999;
          margin-top: -48px;
          display: flex;
          // flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 40px;
        }
        &__text {
          font-size: 20px;
        }
    }
    &__info{
      color: #ffffff;
      width: 90%;
      // font-size: 20px;
      height: 0;
    }
}