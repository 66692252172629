.StoryHeader_BG {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0f0f0f;
    overflow: hidden;
  }

  .StoryHeader {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f2e2d7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../public/imgs/blue_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
  
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  
    @media screen and (min-width: 768px) {
      max-width: 800px;
    }

    &-content {
      position: absolute;
      background-color: #00000090;
      width: calc(85% - 40px);
      height: calc(100% - 40px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding: 20px;
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
      color: #ffffff;

      &-bar {
        z-index: 998;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        padding: 5px;
  
        &__menu {
          font-size: 30px;
          position: absolute;
          left: 0;
          top: 0;
          margin-left: 10px;
          color: #ffffff;
        }
  
        &__logoN {
          width: 60%;
          max-width: 400px;
          object-fit: contain;
          // margin-top: 2%;
          z-index: 2;
        }
      }

      &__logoN {
        width: 60%;
        object-fit: contain;
        position: absolute;
        // margin-top: 2%;
        top:0;
        z-index: 2;
      }

      &__bgMoon {
        width: 100%;
        object-fit: contain;
        position: absolute;
        top:0;
      }

      &__bgEarth {
        width: 100%;
        object-fit: contain;
        position: absolute;
        bottom: 0;
      }
    }
}