  body{
    background-color: #0f0f0f;
  }
  .QuestionAndMenuPage {
    width: 100%;
    height: 100%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  
    @media screen and (min-width: 768px) {
      max-width: 800px;
    }
    background-image: url("../../public/imgs/blue_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  
    @media screen and (min-width: 768px) {
      max-width: 800px;
    }
    
    &-popUp {
      position: absolute;
      z-index:999;
      width:100%;
      height:100%;
      &__closeButton {
        width:100%;
        height:100%;
      }
      &-content{
        position: absolute;
        top:0;
        left: 0;
        z-index:999;
        background-color: rgba(13, 8, 46, 0.85);
        width:80%;
        height:100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-drawCardButton {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          &__titleTextImg {
            width: 35%;
            margin: 6%;
          }
          &__titleImg {
            width: 80%;
          }
        }
        &-historyLogoButton, &-indexButton{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          &__textImg {
            margin: 5%;
            width: 25%;
          }
        }
        &__line {
          margin: 5%;
          width: 96%;
        }

      }
    }
    

    &-bar {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% - 10px);
      height: 40px;
      padding: 5px;

      &__menu {
        font-size: 30px;
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 10px;
        color: #ffffff;
      }

      &__logoN {
        width: 60%;
        max-width: 400px;
        object-fit: contain;
        // margin-top: 2%;
        z-index: 2;
      }
    }

    &__titleImg {
      margin-top: 8%;
      width: 50%;
    }

    &-middle{
      
      width: 100%;
      height: calc(70% - 50px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("../../public/imgs/moon.png");
      background-repeat: no-repeat;
      background-size:contain;
      background-position: center;
      
      &-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
          &__askQuestionImg {
            width: 30%;
            margin-bottom: 10px;
          }

          &__setQuestion {
            z-index:998;
            height: 50px;
            width: calc(65% - 25px);
            margin: 0;
            padding: 8px;
            padding-right: 25px;
            font-size: 16px;
            border: solid 3px transparent;
            border-radius: 10px;
            background: #101530CC;
            resize: none;
            color: #ffffff;
          }

          &-enter {
            width: 62%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color: #ffffff;

            &__button {
              z-index: 999;
              margin-top: -38px;
              display: flex;
              // flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 40px;
            }
            &__text {
              font-size: 20px;
            }
        }
        &__info{
          color: #ffffff;
          width: 65%;
          // font-size: 20px;
          height: 0;
        }
      }
    }
}
