
  .TossResultPage {
      &-result {
        margin-top: 5%;
        z-index: 2;
        width: 100%;
        height: 75%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        &__resultImg {
          
          margin-top: 10%;
          // object-fit: contain;
          width: 100%;
          margin-bottom: 20px;
        }
      }
      &__button{
        margin-top: 10%;
        z-index: 2;
        height: 10%;
      }
}