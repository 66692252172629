.CheckoutForm {

    &-bg{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90vw;
        height: 100%;
        z-index: 99;
        background-color: transparent;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    &-closeBtn{
        cursor: pointer;
        text-align: end;
        font-size: 20px;
        width: 100%;
        height: auto;
    }

    &-logo{
        height: 8%;
    }

    &-dollarFont{
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &-form{
        position: relative;
        width: 100%;
    }

    &-submit{
        position: relative;
        width: 100%;
        padding: 10px;
        margin-top: 100px;
    }
}