  .SelectHxgPagePopup {
    visibility: visible;
    position:absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    z-index: 999;
    color: #ffffff;
    background-color: #00000099;
    &-content {
      position: relative;
      width: 80%;
      &__coin1gImg {
        width: 100%;
        object-fit: contain;
      }
      &__startImg {
        position: absolute;
        height: 8%;
        object-fit: contain;
        left: 50%;
        bottom: 7%;
        transform: translate(-50%, 0);
      }
    }

  }

  .SelectHxgPagePopup.hidden { 
    visibility: hidden;
  }

  .SelectHxgPage {
      &__coin0aImg {
        z-index: 2;
        height: 6%;
        margin-bottom: 20px;
        object-fit: contain;
        margin-bottom: 10%;
      }

      &__coin1bImg {
        z-index: 2;
        height: 4%;
        margin-bottom: 20px;
        object-fit: contain;
        margin-bottom: 8%;
      }

      &__coin1cImg, &__coin1dImg, &__coin1eImg {
        z-index: 3;
        height: 18%;
        margin-bottom: 20px;
        object-fit: contain;
      }
}