.GiftCode{
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0,0.8);
    position: absolute;
    left:0;
    right: 0;
   
    &-closeBtn{
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;
        font-size: 20px;
    }

    &-content{
        width: 80%;
        height: 80%;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-inputFrame{
        width: 80%;
        margin-top: 10px;
    }

    &-input{
        padding: 0;
    }

    &-errorMsg{
        padding-top: 10px;
        color: red
    }
}

.GiftCode2{
    position: relative;
    width: 90%;
    //height: 95%;
    border-radius: 15px;
    margin-top: 2%;
    margin-bottom: 2%;
    background-color: transparent;
    background-color: #F3F4EA;
    
    &-closeBtn{
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;
        font-size: 20px;
    }

    &-content{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        //position: relative;
        background-color: #F3F4EA;;
        border-radius: 8px;
        //left: 50%;
        //top: 50%;
        //transform: translate(-50%,0%);
        
        //white-space: pre-line;
        //text-align: center;
    }

    &-inputHints{
        font-size: 15px;
        flex: 0 0 auto;
        color: rgb(0,0,0,0.5);
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 5px;
    }

    &-inputFrame{
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 768px) {
            height: 4%;
        }
    
        @media screen and (max-width: 768px) {
            height: 5%;
        }
    }

    &-input{
        //padding: 0;
        //margin: 0;
        //width: 50%;
        height: 22px;
        border: 1px;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 10px;
        flex: 1 1 auto;
        box-shadow: 0 0 3px #bbbbbb;
        
    }

    &-sendBtn{
        cursor: pointer;
        font-size: 20px;
        //line-height: 17px;
        flex: 0 0 auto;
        //font-weight: bold;
        //background-color: #54a9eb;
        //text-overflow: ellipsis;
        //padding: 5px 14px 6px;
        //border: none;
        //border-radius: 10px !important;
        //border: 1px;
        //width: 15%;
        //height: 3%;
        margin-right: 10px;
        color: rgb(0,0,0,0.5);
        //text-align: right;

    }

    &-errorMsg{
        color: red
    }

    &-changeLine{
        white-space: pre-wrap;
    }

    &-redeem{
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-redeemBg{
        position: absolute;
        width: 90%;
        height: 95%;
        background-color: #F3F4EA;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-redeemButtonFrame{
        width: 90%;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &__button{
            cursor: pointer;
            margin: 5px;
            border-radius: 5px;
            padding: 5px;
            width: 45%;
            background-color: rgb(0,0,0,0.5);
            text-align: center;
            color: white;
        }
    }

}