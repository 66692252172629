.DrawCard_BG {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0f0f0f;
    overflow: hidden;
  }

  .DrawCardPopup {
    visibility: visible;
    opacity: 0;
    position:absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top:0;
    left:0;
    height: 96%;
    width: 96%;
    z-index: 999;
    color: #ffffff;
    background-color: #00000099;
    padding: 2%;

    &__img {
      height: 95%;
    }

    &-x {
      width: 100%;
      &__img {
          float:right;
      }
    }
  }

  .DrawCardPopup.hidden { 
    visibility: hidden;
  }

  .DrawCard {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f2e2d7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../public/imgs/blue_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
  
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  
    @media screen and (min-width: 768px) {
      max-width: 800px;
    }

    &-x {
        width: 100%;
        &__img {
            float:right;
        }
    }

    &-content {
        background-color: #00000090;
        width: 85%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        padding: 20px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        &__Xcontent {
            width: 100%;
            margin-top: 10px;
        }
        &__historyLogo {
            object-fit: contain;
            width: 20%;
        }
        &__drawTitle {
          // width: 70%;
          height: 8%;
          object-fit: contain;
          margin-bottom: 20px;
        }
        &__drawBackground {
          position: relative;
          height: 70%;
          &-img {
            height: 100%;
          }
          &-moon {
            height: 35%;
            position: absolute;
            top: 31%;
            left: 17%;
          }
        }
        &__drawButton {
          height: 15%;
          object-fit: contain;
          margin-top: 10px;
        }
    }
}

.DrawCard-content__drawBackground-moon.animate {
  -webkit-transform:rotate(1440deg);
  transform:rotate(1440deg);
  -webkit-transition:-webkit-transform 1s linear;
  transition:transform 2s linear;
}

.fade-in {
  animation: fadeIn 1s ease-in forwards; /* 動畫從全透明到 80% 透明度 */
}

@keyframes fadeIn {
  from {
    opacity: 0; /* 全透明 */
  }
  to {
    opacity: 1; /* 指定透明度 */
  }
}